import {
  getUserAccount,
  updateUserAccount as updateUserAccountRaw,
  getUserAccountByUserId,
} from '@/repositories/userAccountRepository'
import type { UserAccount } from '@/entities/userAccount'

const updateUserAccount = async (userId: string, data: Partial<UserAccount> & { password?: string }) => {
  const client = useSupabaseClient()
  const { signIn, signOut } = useAuth()

  const userAccount = await updateUserAccountRaw(userId, data)

  // パスワード変更でDB上のauth.sessionsレコードが削除されるため、再ログイン処理を内部的に行う

  // auth.getUser()を通じてsessionが有効がどうかを確認する
  const res = await client.auth.getUser()

  // sessionが取れる(=エラーがない)場合は何もしない
  if (!res.error) return

  if (data.password) {
    // パスワード変更の場合は、再ログインする
    await signIn(userAccount.email, data.password)
  } else {
    // パスワード変更以外でsessionが消えるケースは未確認だが念の為、singOutしてlocalStorageからも消しておく
    await signOut()
  }
}

export const useUserAccount = () => {
  return {
    getUserAccount,
    getUserAccountByUserId,
    updateUserAccount,
  }
}
